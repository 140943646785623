


































































































































import { Component, Vue } from "vue-property-decorator";
import Wysiwyg from "@/components/reusable/Wysiwyg.vue";
import FormInput from "@/components/reusable/FormInput.vue";
import Icon from "@/components/reusable/Icon.vue";
import { getLink, getImage } from "@/utility/helpers";
import AssetMenu from "@/components/asset/AssetMenu.vue";
import { AssetModel } from "@/models/asset";
import AssetService from "@/services/asset_service";
import UIkit from "uikit";
import { EventBus } from "@/events/index";
import { Validations } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";
import { AuthError, NotFoundError } from "@/services/error_service";
import ConfirmDelete from "../reusable/modals/ConfirmDelete.vue";
import MoveItem from "@/components/reusable/MoveItem.vue";
Component.registerHooks(["beforeRouteLeave"]);
@Component({
  components: {
    Wysiwyg,
    FormInput,
    Icon,
    AssetMenu,
    MoveItem,
  },
})
export default class AssetEditor extends Vue {
  protected showWarningModal = false;
  protected asset = {} as AssetModel;
  protected isNew = false;
  protected assetService = new AssetService();
  protected id = 0;
  protected deleteData: AssetModel[] = [];
  protected toast = false;
  protected messageHtml = "";
  protected className = "success";
  protected getLink = getLink;
  protected getImage = getImage;
  protected moveData: AssetModel[] = [];
  protected moveDestination = {} as AssetModel;
  @Validations()
  validations() {
    return {
      asset: {
        filename: { required },
        display_name: { required },
      },
    };
  }

  created() {
    if (!this.$route.params.id) {
      this.isNew = true;
    } else {
      this.id = parseInt(this.$route.params.id, 10);
      this.getSingleAsset();
    }
  }

  mounted() {
    EventBus.$on(
      "deleteConfirmed",
      (id: number, name: string, final = false) => {
        this.deleteRequest(id, name, final);
      }
    );
    /** Global event listener for data deletion. Triggers & sends array of data selected for deletion through to confirmation modal.
     * This event is called from the <Delete> component (a child in the corresponding <Menu> component [@ex: <ProductMenu>, <MfrMenu>...]) and from the base <Table> component.
     */
    EventBus.$on("deleteRow", (data: AssetModel[]) => {
      this.deleteData = data;
      this.$modal.show(
        ConfirmDelete,
        { deleteData: this.deleteData, type: "asset" },
        { height: "auto", adaptive: true }
      );
    });
  }

  protected async sendMoveRequest(
    newParent: string,
    id: number,
    name: string,
    origin: AssetModel,
    final = false
  ): Promise<void> {
    try {
      await this.assetService.saveAsset(
        { filename: origin.filename, path: newParent },
        id
      );
      this.getSingleAsset();
      this.showWarningModal = false;
      EventBus.$emit(
        "showSuccess",
        `Asset <strong>${
          this.asset.display_name
            ? this.asset.display_name
            : this.asset.filename
        }</strong> has been moved.`,
        []
      );
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        EventBus.$emit(
          "showFail",
          `The move request for the following asset failed. Please try again.`,
          []
        );
      }
    }
    this.moveData = [];
  }

  protected setDestination(value: AssetModel) {
    this.moveDestination = value;
  }
  protected move() {
    this.moveData.push(this.asset);
    UIkit.modal(document.getElementById("move-modal") as HTMLElement).show();
  }

  beforeRouteLeave(to: any, from: any, next: any) {
    if (this.showWarningModal) {
      UIkit.modal
        .confirm(
          `
    <div class="uk-modal-header uk-flex uk-flex-middle">
      <div class="uk-flex-none">
        <span
          uk-icon="icon: warning; ratio:1.5;"
          class="red no-hover uk-margin-small-right"
        ></span>
      </div>
      <div>
        <h2 class="uk-modal-title uk-margin-remove">
          You have not saved your changes!
        </h2>
      </div>
    </div>
    <div class="uk-modal-body">
      Would you like to continue without saving your changes?
    </div>`
        )
        .then(
          function() {
            next();
          },
          function() {
            next(false);
          }
        );
    } else {
      next();
    }
  }

  protected showWarning(isVisible: boolean): void {
    this.showWarningModal = isVisible;
  }

  beforeDestroy() {
    EventBus.$off("deleteConfirmed");
    EventBus.$off("deleteRow");
    /** UIkit modals do not leave the DOM unless explicitly destroyed. Destroying them helps with buggy functionality due to dynamic data. This method loops through all of the modal ids and remove
     * them from the DOM upon vue's beforeDestroy() lifecycle hook.
     *
     * Note that typescript does not have definitions for many UIkit methods, hence //@ts-ignore flag.
     */
    const modals = [
      "#delete-modal",
      "#move-modal",
      "#confirm-moving-modal",
      "#add-model",
      "#save-modal",
    ];
    modals.forEach((selector) => {
      const component = UIkit.modal(selector);
      if (component) {
        //@ts-ignore
        component.$destroy(true);
      }
    });
  }

  protected cancel(): void {
    this.$router.go(-1);
  }

  protected closeToast(): void {
    this.toast = false;
  }

  protected showToast(message: string, className: string): void {
    this.messageHtml = message;
    this.className = className;
    this.toast = true;
  }

  protected save(): void {
    this.$v.asset.$touch();
    if (!this.$v.$invalid) {
      this.saveExisting();
    }
  }

  protected saveKeypress(): void {
    this.save();
  }
  protected get website(): string {
    return process.env.VUE_APP_C5_URL;
  }

  protected async getSingleAsset(): Promise<void> {
    try {
      const res: AssetModel = await this.assetService.getSingleAsset(this.id);
      this.asset = res;
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else if (err instanceof NotFoundError) {
        this.$router.replace({
          name: "NotFound",
          query: { error: encodeURI(err.message) },
        });
      } else {
        EventBus.$emit("showError", err.message);
      }
    }
  }

  /**
   * @param id id of item to be deleted
   * @param name name of item to be deleted, used in <Toast> confirmation
   * @param final optional, default: false, flags the final item in the request array; triggers <Toast> confirmation, refreshes data
   *
   * in the <{Path}Editor> component, @param final is not used.
   */
  protected async deleteRequest(
    id: number,
    name: string,
    final = false
  ): Promise<void> {
    try {
      await this.assetService.deleteAsset(id);
      this.$router.push({
        path: "/asset",
        query: {
          deleted: encodeURI(`${this.asset.filename}`),
          type: "asset",
        },
      });
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        EventBus.$emit("showError", err.message);
      }
    }
  }

  // protected async postNew(): Promise<void> {
  //   try {
  //     await this.assetService.createNewAsset(this.asset);
  //     this.showToast(
  //       `Asset <strong>${this.asset.filename}</strong> has been ${this.action}. <a href="${this.website}/assets/${this.asset.filename}" target="_blank">View Live</a>`,
  //       "success"
  //     );
  //   } catch (err) {
  //     if (err instanceof AuthError) {
  //       AuthError.logout();
  //     } else {
  //       EventBus.$emit("showError", err.message);
  //     }
  //   }
  //
  // }

  protected async saveExisting(): Promise<void> {
    try {
      await this.assetService.saveAsset(this.asset, this.asset.id as number);
      if (this.$route.query.created) {
        this.$router.push({ query: {} });
      }
      this.getSingleAsset();
      this.showWarningModal = false;
      EventBus.$emit(
        "showSuccess",
        `Product <strong>${this.asset.filename}</strong> has been saved.`,
        []
      );
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        EventBus.$emit("showError", err.message);
      }
    }
  }

  get action(): string {
    let action = "saved";
    if (this.isNew) {
      action = "created";
    }
    return action;
  }
}
